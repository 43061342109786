import { takeEvery, call, put } from "redux-saga/effects"
import { fetchExpertsSuccess, fetchExpertsFailure } from "./actions"
import { FETCH_EXPERTS_REQUEST } from "./actionTypes"
import { getFirebaseBackend } from "../../helpers/firebase_helper"

const fireBaseBackend = getFirebaseBackend()

function* fetchExpertsSaga() {
  try {
    const response = yield call(fireBaseBackend.fetchExperts)
    yield put(fetchExpertsSuccess(response))
  } catch (error) {
    yield put(fetchExpertsFailure(error.message))
  }
}

function* watchFetchExperts() {
  yield takeEvery(FETCH_EXPERTS_REQUEST, fetchExpertsSaga)
}

export default function* ExpertsSaga() {
  yield watchFetchExperts()
}

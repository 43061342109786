import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import {
  getEvents,
  getProfile,
  getUsers,
  getChartsData as onGetChartsData,
} from "../../store/actions"

import { format, addDays } from "date-fns"
// Image
import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestTranaction from "./LatestTranaction"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

const Dashboard = props => {
  const { t, i18n } = useTranslation()
  const [modal, setModal] = useState(false)
  const [subscribeModal, setSubscribeModal] = useState(false)
  const [appointments, setAppointments] = useState([])
  const [upcomingAppointments, setUpcomingAppointments] = useState([])
  const [reports, setReports] = useState([
    {
      title: t("Upcoming Appointment"),
      iconClass: "bx-copy-alt",
      description: "1,235",
    },
    {
      title: t("Past Appointment"),
      iconClass: "bx-archive-in",
      description: "1,235",
    },
    {
      title: t("Cancel Appointment"),
      iconClass: "bx-purchase-tag-alt",
      description: "1,235",
    },
    {
      title: t("Pending Appointment"),
      iconClass: "bx-copy-alt",
      description: "1,235",
    },
    {
      title: t("Total Appointment"),
      iconClass: "bx-copy-alt",
      description: "0",
    },
    {
      title: t("Total Customer"),
      iconClass: "bx-copy-alt",
      description: "1,235",
    },
  ])

  const DashboardProperties = createSelector(
    state => state.Dashboard,
    dashboard => ({
      chartsData: dashboard.chartsData,
    })
  )
  const dispatch = useDispatch()

  const { chartsData } = useSelector(DashboardProperties)

  const CalendarProperties = createSelector(
    state => state.calendar,
    Calendar => ({
      events: Calendar.events,
    })
  )

  const { events } = useSelector(CalendarProperties)

  const ContactsProperties = createSelector(
    state => state.contacts,
    Contacts => ({
      users: Contacts.users,
      loading: Contacts.loading,
    })
  )

  const { users } = useSelector(ContactsProperties)

  const handleDates = () => {
    var date = new Date()

    const currentYear = new Date().getFullYear() // Şu anki yılı alır

    // Yılın ilk günü (1 Ocak)
    const firstDay = new Date(currentYear, 0, 1) // 0: Ocak
    // Yılın son günü (31 Aralık)
    const lastDay = new Date(currentYear, 11, 31) // 11: Aralık

    const dates = []
    let currentDate = firstDay

    while (currentDate < lastDay) {
      dates.push(format(currentDate, "dd-MM-yyyy")) // 'DD-MM-YYYY' formatında tarih ekle
      currentDate = addDays(currentDate, 1) // Bir sonraki güne geç
    }
    dispatch(getEvents(dates))
  }

  useEffect(() => {
    handleDates()
    if (users && !users.length) {
      dispatch(getUsers())
    }
  }, [dispatch, users])

  useEffect(() => {
    try {
      if (events) {
        const arr = events
          .filter(item => item.appointmentName)
          .map(item => {
            return {
              title: `${item.time} - ${item.appointmentName}`,
              date: item.date,
              appointmentName: item.appointmentName,
              className: item.className,
              clientEmail: item.clientEmail,
              clientName: item.clientName,
              clientPhoneNumber: item.clientPhoneNumber,
              appointmentstatus: item.appointmentstatus,
              time: item.time,
            }
          })
        setAppointments(arr)
      }
    } catch (error) {
      console.error(error)
    }
  }, [events])

  useEffect(() => {
    try {
      let temp = [...reports]
      if (appointments) {
        const now = new Date()

        temp[4].description = appointments.length

        const cancel = appointments.filter(
          item => item.appointmentstatus === "bg-dark"
        )
        const past = appointments.filter(
          item => new Date(`${item.date}T${item.time}:00`) < now
        )
        const upcoming = appointments.filter(
          item => new Date(`${item.date}T${item.time}:00`) > now
        )
        const waiting = appointments.filter(
          item => item.appointmentstatus === "bg-danger"
        )
        temp[0].description = upcoming.length
        temp[1].description = past.length
        temp[2].description = cancel.length
        temp[3].description = waiting.length
        setUpcomingAppointments(upcoming)
      }
      if (users) {
        temp[5].description = users.length
      }
      setReports(temp)
    } catch (error) {
      console.error(error)
    }
  }, [appointments, users])

  useEffect(() => {
    setTimeout(() => {
      //setSubscribeModal(true)
    }, 2000)
  }, [])

  const ProfileProperties = createSelector(
    state => state.Profile,
    Profile => ({
      user: Profile.user,
      loading: Profile.loading,
    })
  )

  const { user, loading } = useSelector(ProfileProperties)

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
    if (!user) {
      dispatch(getProfile())
    }
  }, [dispatch])

  //meta title
  document.title = t("Dashboard | Appointment Panel - Ocalibus Software")

  const experts = useSelector(state => state.ExpertReducer.experts)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp user={user} />
              {/*<MonthlyEarning />*/}
            </Col>
            <Col xl="8">
              <Row>
                {(reports || [])?.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              {/*<Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Email Sent</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly")
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly")
                            }}
                            id="one_month"
                          >
                            Month
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly")
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <StackedColumnChart
                    periodData={periodData}
                    dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'
                  />
                </CardBody>
              </Card>*/}
            </Col>
          </Row>

          <Row>
            {/*<Col xl="4">
              <SocialSource />
            </Col>*/}
            <Col xl="4">
              <ActivityComp upcomingAppointments={upcomingAppointments} />
            </Col>

            <Col xl="4">
              <TopCities users={users} />
            </Col>
          </Row>

          {/*<Row>
            <Col lg="12">
              <LatestTranaction />
            </Col>
          </Row>*/}
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribeModal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribeModal(!subscribeModal)
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribeModal(!subscribeModal)
            }}
          ></ModalHeader>
        </div>
        <ModalBody>
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <Row className="justify-content-center">
              <Col xl={10}>
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div className="input-group rounded bg-light">
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setModal(!modal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setModal(!modal)
            }}
          >
            {" "}
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setModal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)

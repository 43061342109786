import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"
import { useTranslation } from "react-i18next"

const WelcomeComp = ({ user }) => {
  const { t, i18n } = useTranslation()
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary-subtle">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">{t("Welcome !")}</h5>
                <p>{t("Appointment Panel")}</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <span className="avatar-title rounded-circle">
                  {user?.username?.charAt(0)}{" "}
                </span>
              </div>
              <h5 className="font-size-15 text-truncate">{user?.username}</h5>
              <p className="text-muted mb-0 text-truncate">
                {user?.specialization}
              </p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                {/*<Row>
                  <Col xs="6">
                    <h5 className="font-size-15">125</h5>
                    <p className="text-muted mb-0">Projects</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">$1245</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col>
                </Row>*/}
                <div className="mt-4">
                  <Link to="/profile" className="btn btn-primary  btn-sm">
                    {t("View Profile")}{" "}
                    <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp

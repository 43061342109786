import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//import Images
import verification from "../../assets/images/verification-img.png"
import { format, addDays } from "date-fns"

import {
  getUsers,
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  getCategories as onGetCategories,
  getEvents as onGetEvents,
  updateEvent as onUpdateEvent,
} from "../../store/actions"

import DeleteModal from "./DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useTranslation } from "react-i18next"

const Calender = props => {
  const { t, i18n } = useTranslation()
  //meta title
  document.title = t("Calendar | Appointment Panel - Ocalibus Software")

  const dispatch = useDispatch()

  const [event, setEvent] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [filteredClients, setFilteredClients] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)

  // category validation
  const categoryValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      appointmentName: (event && event.appointmentName) || "",
      //category: (event && event.category) || "",
      status: (event && event.appointmentstatus) || "",
      clientName: (event && event.clientName) || "",
      clientPhoneNumber: (event && event.clientPhoneNumber) || "",
      clientEmail: (event && event.clientEmail) || "",
      city: (event && event.clientEmail) || "",
      time: (event && event.time) || "",
    },
    validationSchema: Yup.object({
      appointmentName: Yup.string().required(
        t("Please Enter Your Appointment Name")
      ),
      time: Yup.string().required(t("Please Enter Your Appointment Time")),
      clientName: Yup.string().required(
        t("Please Enter Your Client Name And Surname")
      ),
      city: Yup.string().required(t("Please Enter Your Client City")),
      clientEmail: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please Enter Valid Email")
        .required(t("Please Enter Your Client Email")),
      clientPhoneNumber: Yup.string()
        .matches(
          /^\+\d{1,3}\d{10}$/,
          "Geçersiz telefon numarası. + ile başlayan ve alan kodu içeren 10 haneli bir numara girin."
        )
        .required(t("Please Enter Your Client Phone Number")),
      //category: Yup.string().required(t("Please Enter Your Appointment Name")),
      status: Yup.string().required(t("Please Enter Your Appointment Status")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          appointmentName: values["appointmentName"],
          start: event.start
            ? event.start
            : selectedDay
            ? selectedDay.date
            : new Date(),
          clientEmail: values["clientEmail"],
          city: values["city"],
          clientName: values["clientName"],
          clientPhoneNumber: values["clientPhoneNumber"],
          time: values["time"],
          appointmentstatus: values["status"],
          className: values["status"]
            ? values["status"] + " text-white"
            : "bg-primary text-white",
        }
        // update event
        dispatch(onUpdateEvent(updateEvent))
        categoryValidation.resetForm()
      } else {
        const newEvent = {
          appointmentName: values["appointmentName"],
          start: selectedDay ? selectedDay.date : new Date(),
          clientEmail: values["clientEmail"],
          city: values["city"],
          clientName: values["clientName"],
          clientPhoneNumber: values["clientPhoneNumber"],
          time: values["time"],
          appointmentstatus: values["status"],
          className: values["status"]
            ? values["status"] + " text-white"
            : "bg-primary text-white",
        }
        // save new event
        dispatch(onAddNewEvent(newEvent))
        categoryValidation.resetForm()
      }
      toggle()
    },
  })

  const CalendarProperties = createSelector(
    state => state.calendar,
    Calendar => ({
      events: Calendar.events,
      categories: Calendar.categories,
    })
  )

  const { events, categories } = useSelector(CalendarProperties)

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [modalCategory, setModalCategory] = useState(false)
  const [selectedDay, setSelectedDay] = useState(0)
  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    try {
      if (events) {
        const arr = events
          .filter(item => item.appointmentName)
          .map(item => {
            return {
              title: `${item.time} - ${item.appointmentName}`,
              date: item.date,
              appointmentName: item.appointmentName,
              className: item.className,
              clientEmail: item.clientEmail,
              city: item.city,
              clientName: item.clientName,
              clientPhoneNumber: item.clientPhoneNumber,
              appointmentstatus: item.appointmentstatus,
              status: item.appointmentstatus,
              time: item.time,
              id: item.id,
            }
          })
        setAppointments(arr)
      }
    } catch (error) {
      console.error(error)
    }
  }, [events])

  useEffect(() => {
    if (!modalCategory && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modalCategory, event, isEdit])

  const handleDates = arg => {
    const start = new Date(arg.start)
    const end = new Date(arg.end)

    const dates = []
    let currentDate = start

    while (currentDate < end) {
      dates.push(format(currentDate, "dd-MM-yyyy")) // 'DD-MM-YYYY' formatında tarih ekle
      currentDate = addDays(currentDate, 1) // Bir sonraki güne geç
    }
    dispatch(onGetEvents(dates))
  }

  const ContactsProperties = createSelector(
    state => state.contacts,
    Contacts => ({
      users: Contacts.users,
      loading: Contacts.loading,
    })
  )

  const { users, loading } = useSelector(ContactsProperties)

  useEffect(() => {
    if (users && !users.length) {
      dispatch(getUsers())
    }
  }, [dispatch, users])

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modalCategory) {
      setModalCategory(false)
      setEvent(null)
      setIsEdit(false)
    } else {
      setModalCategory(true)
    }
  }

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    const date = arg["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )
    const modifiedData = { ...arg, date: modifiedDate }

    setSelectedDay(modifiedData)
    toggle()
  }
  const hours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, "0"))
  const minutes = ["00", "15", "30", "45"]
  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {
    const event = arg.event

    setEvent({
      id: event.id,
      appointmentName: event._def.extendedProps.appointmentName,
      // title_category: event.title_category,
      start: arg.event._instance.range.start,
      className: event._def.extendedProps.className,
      clientEmail: event._def.extendedProps.clientEmail,
      city: event._def.extendedProps.city,
      clientName: event._def.extendedProps.clientName,
      clientPhoneNumber: event._def.extendedProps.clientPhoneNumber,
      time: event._def.extendedProps.time,
      status: event._def.extendedProps.appointmentstatus,
    })
    setDeleteId(event.id)
    setIsEdit(true)
    setModalCategory(true)
    toggle()
  }

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    if (deleteId) {
      dispatch(onDeleteEvent(deleteId))
    }
    setDeleteModal(false)
  }

  /**
   * On category darg event
   */
  const onDrag = event => {
    event.preventDefault()
  }

  /**
   * On calendar drop event
   */
  const onDrop = event => {
    console.log(event)
    const date = event["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )

    const draggedEl = event.draggedEl
    const draggedElclass = draggedEl.className
    if (
      draggedEl.classList.contains("external-event") &&
      draggedElclass.indexOf("fc-event-draggable") == -1
    ) {
      const modifiedData = {
        id: Math.floor(Math.random() * 100),
        appointmentName: draggedEl.innerText,
        start: modifiedDate,
        className: draggedEl.className,
      }
      dispatch(onAddNewEvent(modifiedData))
    }
  }

  //set the local language
  const enLocal = {
    code: "en-nz",
    week: {
      dow: 1,
      doy: 4,
    },
    buttonHints: {
      prev: "Previous $0",
      next: "Next $0",
      today: "This $0",
    },
    viewHint: "$0 view",
    navLinkHint: "Go to $0",
  }
  const trLocal = {
    code: "tr",
    week: {
      dow: 1,
      doy: 4,
    },
    buttonHints: {
      prev: "Previous $0",
      next: "Next $0",
      today: "This $0",
    },
    viewHint: "$0 view",
    navLinkHint: "Go to $0",
  }
  const [isLocal, setIsLocal] = useState(trLocal)
  const handleChangeLocals = value => {
    setIsLocal(value)
  }

  const handleInputChange = event => {
    const userInput = event.target.value
    const suggestions = users.filter(client =>
      client.name.toLowerCase().includes(userInput.toLowerCase())
    )
    setFilteredClients(suggestions)
    setShowSuggestions(true)
    categoryValidation.handleChange(event)
  }

  const handleSuggestionClick = client => {
    setShowSuggestions(false)
    categoryValidation.setFieldValue("clientName", client.name)
    categoryValidation.setFieldValue("clientPhoneNumber", client.phone)
    categoryValidation.setFieldValue("clientEmail", client.email)
    categoryValidation.setFieldValue("city", client.city)
  }

  const handleBlur = async () => {
    setTimeout(async () => {
      setShowSuggestions(false)
    }, 400)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={t("Calendar")}
            breadcrumbItem={t("Full Calendar")}
          />
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      {/* fullcalendar control */}
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          listPlugin,
                          interactionPlugin,
                        ]}
                        datesSet={handleDates}
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                        }}
                        buttonText={{
                          today: "Bugün",
                          month: "Ay",
                          week: "Hafta",
                          day: "Gün",
                          list: "Liste",
                        }}
                        locale={"tr"}
                        events={appointments}
                        editable={true}
                        droppable={false}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        drop={onDrop}
                      />
                    </CardBody>
                  </Card>

                  <Modal
                    isOpen={modalCategory}
                    className={props.className}
                    centered
                  >
                    <ModalHeader toggle={toggle} tag="h5">
                      {!!isEdit ? t("Edit Appointment") : t("Add Appointment")}
                    </ModalHeader>
                    <ModalBody className="p-4">
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          categoryValidation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label>{t("Appointment Name")}</Label>
                              <Input
                                name="appointmentName"
                                type="text"
                                placeholder={t("Insert Appointment Name")}
                                onChange={categoryValidation.handleChange}
                                onBlur={categoryValidation.handleBlur}
                                value={
                                  categoryValidation.values.appointmentName ||
                                  ""
                                }
                                invalid={
                                  categoryValidation.touched.appointmentName &&
                                  categoryValidation.errors.appointmentName
                                    ? true
                                    : false
                                }
                              />
                              {categoryValidation.touched.appointmentName &&
                              categoryValidation.errors.appointmentName ? (
                                <FormFeedback type="invalid">
                                  {categoryValidation.errors.appointmentName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label>{t("Appointment Hour")}</Label>
                              <Input
                                type="select"
                                id="timeSelect"
                                name="time"
                                placeholder={t("Insert Appointment Hour")}
                                onChange={categoryValidation.handleChange}
                                onBlur={categoryValidation.handleBlur}
                                value={categoryValidation.values.time || ""}
                                invalid={
                                  categoryValidation.touched.time &&
                                  categoryValidation.errors.time
                                    ? true
                                    : false
                                }
                              >
                                {hours.map(hour =>
                                  minutes.map(minute => (
                                    <option
                                      key={`${hour}:${minute}`}
                                      value={`${hour}:${minute}`}
                                    >
                                      {`${hour}:${minute}`}
                                    </option>
                                  ))
                                )}
                              </Input>
                              {/*<Input
                                name="time"
                                type="time"
                                placeholder={t("Insert Appointment Hour")}
                                onChange={categoryValidation.handleChange}
                                onBlur={categoryValidation.handleBlur}
                                value={categoryValidation.values.time || ""}
                                invalid={
                                  categoryValidation.touched.time &&
                                  categoryValidation.errors.time
                                    ? true
                                    : false
                                }
                              />*/}
                              {categoryValidation.touched.time &&
                              categoryValidation.errors.time ? (
                                <FormFeedback type="invalid">
                                  {categoryValidation.errors.time}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label>{t("Client Name/Surname")}</Label>

                              <Input
                                name="clientName"
                                type="text"
                                placeholder="Client Name/Surname"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={
                                  categoryValidation.values.clientName || ""
                                }
                                invalid={
                                  categoryValidation.touched.clientName &&
                                  categoryValidation.errors.clientName
                                    ? true
                                    : false
                                }
                              />
                              {showSuggestions &&
                                filteredClients.length > 0 && (
                                  <ListGroup>
                                    {filteredClients.map(client => (
                                      <ListGroupItem
                                        key={client.id}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleSuggestionClick(client)
                                        }
                                      >
                                        {client.name}
                                      </ListGroupItem>
                                    ))}
                                  </ListGroup>
                                )}
                              {categoryValidation.touched.clientName &&
                              categoryValidation.errors.clientName ? (
                                <FormFeedback type="invalid">
                                  {categoryValidation.errors.clientName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label>{t("Client Phone Number")}</Label>
                              <Input
                                name="clientPhoneNumber"
                                type="text"
                                placeholder={t("Insert Phone")}
                                onChange={categoryValidation.handleChange}
                                onBlur={categoryValidation.handleBlur}
                                value={
                                  categoryValidation.values.clientPhoneNumber ||
                                  ""
                                }
                                invalid={
                                  categoryValidation.touched
                                    .clientPhoneNumber &&
                                  categoryValidation.errors.clientPhoneNumber
                                    ? true
                                    : false
                                }
                              />
                              {categoryValidation.touched.clientPhoneNumber &&
                              categoryValidation.errors.clientPhoneNumber ? (
                                <FormFeedback type="invalid">
                                  {categoryValidation.errors.clientPhoneNumber}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label>{t("Client Email")}</Label>
                              <Input
                                name="clientEmail"
                                type="text"
                                placeholder={t("Insert Email")}
                                onChange={categoryValidation.handleChange}
                                onBlur={categoryValidation.handleBlur}
                                value={
                                  categoryValidation.values.clientEmail || ""
                                }
                                invalid={
                                  categoryValidation.touched.clientEmail &&
                                  categoryValidation.errors.clientEmail
                                    ? true
                                    : false
                                }
                              />
                              {categoryValidation.touched.clientEmail &&
                              categoryValidation.errors.clientEmail ? (
                                <FormFeedback type="invalid">
                                  {categoryValidation.errors.clientEmail}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">{t("City")}</Label>
                              <Input
                                name="city"
                                label={t("City")}
                                type="text"
                                placeholder={t("Insert City")}
                                onChange={categoryValidation.handleChange}
                                onBlur={categoryValidation.handleBlur}
                                value={categoryValidation.values.city || ""}
                                invalid={
                                  categoryValidation.touched.city &&
                                  categoryValidation.errors.city
                                    ? true
                                    : false
                                }
                              />
                              {categoryValidation.touched.city &&
                              categoryValidation.errors.city ? (
                                <FormFeedback type="invalid">
                                  {categoryValidation.errors.city}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label>{t("Status")}</Label>
                              <Input
                                type="select"
                                name="status"
                                placeholder={t("Appointment Status")}
                                onChange={categoryValidation.handleChange}
                                onBlur={categoryValidation.handleBlur}
                                value={categoryValidation.values.status || ""}
                                invalid={
                                  categoryValidation.touched.status &&
                                  categoryValidation.errors.status
                                    ? true
                                    : false
                                }
                              >
                                <option value="bg-danger">
                                  {t("Waiting")}
                                </option>
                                <option value="bg-success">
                                  {t("Confirmed")}
                                </option>
                                {/*<option value="bg-primary">
                                  {t("Upcoming")}
                                </option>
                                <option value="bg-info">{t("Past")}</option>*/}
                                <option value="bg-dark">{t("Cancel")}</option>
                              </Input>
                              {categoryValidation.touched.status &&
                              categoryValidation.errors.status ? (
                                <FormFeedback type="invalid">
                                  {categoryValidation.errors.status}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          {/*<Col xs={12}>
                            <div className="mb-3">
                              <Label>{t("Category")}</Label>
                              <Input
                                type="select"
                                name="category"
                                placeholder="All Day Event"
                                onChange={categoryValidation.handleChange}
                                onBlur={categoryValidation.handleBlur}
                                value={categoryValidation.values.category || ""}
                                invalid={
                                  categoryValidation.touched.category &&
                                  categoryValidation.errors.category
                                    ? true
                                    : false
                                }
                              >
                                <option value="bg-danger">Danger</option>
                                <option value="bg-success">Success</option>
                                <option value="bg-primary">Primary</option>
                                <option value="bg-info">Info</option>
                                <option value="bg-dark">Dark</option>
                                <option value="bg-warning">Warning</option>
                              </Input>
                              {categoryValidation.touched.category &&
                              categoryValidation.errors.category ? (
                                <FormFeedback type="invalid">
                                  {categoryValidation.errors.category}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>*/}
                        </Row>

                        <Row className="mt-2">
                          <Col xs={6}>
                            {isEdit && (
                              <Button
                                type="button"
                                color="btn btn-danger"
                                id="btn-delete-event"
                                onClick={() => {
                                  toggle()
                                  setDeleteModal(true)
                                }}
                              >
                                {t("Delete")}
                              </Button>
                            )}
                          </Col>

                          <Col xs={6} className="text-end">
                            <Button
                              color="light"
                              type="button"
                              className="me-1"
                              onClick={toggle}
                            >
                              {t("Close")}
                            </Button>
                            <Button
                              type="submit"
                              color="success"
                              id="btn-save-event"
                            >
                              {t("Save")}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default Calender

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Container, Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

//Import Images
import logo from "../../../assets/images/app_logo.png"
import LanguageDropdown from "components/CommonForBoth/TopbarDropdown/LanguageDropdown"

const navItems = [
  { id: 1, idnm: "home", navheading: "Home" },
  { id: 2, idnm: "about", navheading: "About" },
  { id: 3, idnm: "features", navheading: "Features" },
  { id: 3, idnm: "roadmap", navheading: "Roadmap" },
  { id: 4, idnm: "team", navheading: "Team" },
  { id: 5, idnm: "news", navheading: "News" },
  { id: 6, idnm: "faqs", navheading: "FAQs" },
]

const Navbar_Page = props => {
  const { t, i18n } = useTranslation()
  const [isOpenMenu, setisOpenMenu] = useState(false)
  const [navClass, setNavClass] = useState("")

  //Store all NavigationbaFr Id into TargetID variable(Used for Scrollspy)
  let TargetId = navItems.map(item => {
    return item.idnm
  })

  const scrollNavigation = () => {
    var scrollUp = document.documentElement.scrollTop
    if (scrollUp > 50) {
      setNavClass("sticky nav-sticky")
    } else {
      setNavClass("")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true)
  })

  return (
    <React.Fragment>
      <nav
        className={
          "navbar navbar-expand-lg navigation fixed-top sticky " + navClass
        }
        style={{ maxHeight: 70 }}
      >
        <Container style={{ justifyContent: "center" }}>
          <Link className="navbar-logo" to="/">
            <img
              src={logo}
              alt="Appointment Panel"
              style={{ height: 30 }}
              className="logo logo-dark"
            />
            <img
              src={logo}
              alt="Appointment Panel"
              style={{ height: 30 }}
              className="logo logo-light"
            />
          </Link>
          <h1
            style={{
              marginBottom: 0,
              color: "#333",
              fontSize: 22,
              marginLeft: 0,
            }}
          >
            {t("Appointment Panel")}
          </h1>

          <Collapse id="topnav-menu-content" isOpen={isOpenMenu} navbar>
            <div style={{ height: 70, flex: 1 }} />
            <LanguageDropdown />

            <div className="ms-lg-2">
              <Link
                to="/login"
                className="font-size-16 btn btn-outline-success w-xs"
              >
                {t("Log In")}
              </Link>
            </div>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  )
}

Navbar_Page.propTypes = {
  imglight: PropTypes.any,
  navClass: PropTypes.string,
}

export default Navbar_Page

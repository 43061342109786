import React from "react"
import { Container, Row, Col, Card } from "reactstrap"
import { Link } from "react-router-dom"

import { useTranslation } from "react-i18next"
import desktop3 from "../../assets/images/app-home-randevu.png"

const Section = () => {
  const { t, i18n } = useTranslation()

  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero" id="home">
        {/*<div className="bg-overlay bg-primary"></div>*/}
        <Container>
          <Row className="align-items-center">
            <Col lg={7}>
              <div className="text-white-50">
                <h1
                  className="fw-semibold mb-3 hero-title"
                  style={{ color: "#333", fontSize: 28 }}
                >
                  {t("Your Time, Perfectly Managed")}
                </h1>
                <p style={{ color: "#333" }} className="font-size-16">
                  {t("Hero Text")}
                </p>

                <div className="d-flex flex-wrap gap-2 mt-4">
                  <Link to="login" className="font-size-16 btn btn-success">
                    {t("Get Started")}
                  </Link>
                  {/*<Link to="#" className="btn btn-light">
                    How it work
                  </Link>*/}
                </div>
              </div>
            </Col>
            <Col lg={5} className="ms-lg-auto">
              <img
                src={desktop3}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: 400,
                  objectFit: "contain",
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Section

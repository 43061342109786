import React from "react"
import { Container, Row, Col } from "reactstrap"

//Import Components
import FeatureBox from "./feature-box"

//Import images
import feature1 from "../../../../assets/images/cyber-security.jpg"
import feature2 from "../../../../assets/images/access-for-anywhere.jpg"
import feature3 from "../../../../assets/images/appointment_1.png"
import feature4 from "../../../../assets/images/app-randevu-paneli-homepage.png"
import appointment_2 from "../../../../assets/images/appointment_2.png"
import feature5 from "../../../../assets/images/doctor-appointment.jpg"
import feature6 from "../../../../assets/images/psikolog-randevu.jpg"
import whos from "../../../../assets/images/hairdresser-appointment.jpg"
import kuaf from "../../../../assets/images/kuaforler-icin-randevu-paneli.jpg"
import { useTranslation } from "react-i18next"

const Features = () => {
  const { t, i18n } = useTranslation()
  const features1 = [
    { id: 1, desc: t("Data Security2") },
    { id: 2, desc: t("Data Security3") },
  ]
  const features2 = [
    { id: 1, desc: t("Access from Anywhere2") },
    { id: 2, desc: t("Access from Anywhere3") },
  ]

  return (
    <React.Fragment>
      <section className="section" id="features">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="font-size-16 small-title">{t("Features")}</div>
                <h4 className="font-size-20">
                  {t("Key features of the product")}
                </h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center pt-4">
            <Col md="6" sm="8">
              <div>
                <img
                  src={feature1}
                  alt="Appointment Panel Heryerden Erişim"
                  className="img-fluid mx-auto d-block"
                  style={{ borderRadius: 10, maxHeight: 400 }}
                />
              </div>
            </Col>
            <Col md="5" className="ms-auto">
              {/* featurebox */}
              <FeatureBox
                num="01"
                title={t("Data Security")}
                features={features1}
                desc={t("Data Security1")}
              />
            </Col>
          </Row>

          <Row className="align-items-center pt-md-5">
            <Col md="5">
              {/* featurebox */}
              <FeatureBox
                num="02"
                title={t("Access from Anywhere")}
                features={features2}
                desc={t("Access from Anywhere1")}
              />
            </Col>
            <Col md="6" sm="8" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src={feature2}
                  alt="Appointment Panel Güvenilir"
                  className="img-fluid mx-auto d-block"
                  style={{ borderRadius: 10, maxHeight: 350 }}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 40 }}>
            <Col lg="12">
              <div className="text-center mb-5">
                <h4>{t("Who Can Use It?")}</h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center pt-4">
            <Col md="6" sm="8">
              <div>
                <img
                  src={feature5}
                  alt="Appointment Panel Heryerden Erişim"
                  className="img-fluid mx-auto d-block"
                  style={{ borderRadius: 10, maxHeight: 350 }}
                />
              </div>
            </Col>
            <Col md="5" className="ms-auto">
              {/* featurebox */}
              <FeatureBox
                num="01"
                title={t("Doctors and Healthcare Professionals")}
                desc={t("Doctors and Healthcare Professionals_Text")}
              />
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col md="5">
              {/* featurebox */}
              <FeatureBox
                num="02"
                title={t("Psychologists and Counselors")}
                desc={t("Psychologists and Counselors_Text")}
              />
            </Col>
            <Col md="6" sm="8" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src={feature6}
                  alt="Appointment Panel Güvenilir"
                  className="img-fluid mx-auto d-block"
                  style={{ borderRadius: 10, maxHeight: 350 }}
                />
              </div>
            </Col>
          </Row>

          <Row className="align-items-center pt-4">
            <Col md="6" sm="8">
              <div>
                <img
                  src={whos}
                  alt="Kimler Randevu Paneli Kullanabilir"
                  className="img-fluid mx-auto d-block"
                  style={{ borderRadius: 10, maxHeight: 350 }}
                />
              </div>
            </Col>
            <Col md="5" className="ms-auto">
              {/* featurebox */}
              <FeatureBox
                num="03"
                title={t("Hairdressers and Beauticians")}
                desc={t("Hairdressers and Beauticians_Text")}
              />
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col md="5">
              {/* featurebox */}
              <FeatureBox
                num="04"
                title={t("Life Coaches and Trainers")}
                desc={t("Life Coaches and Trainers_Text")}
              />
            </Col>
            <Col md="6" sm="8" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src={kuaf}
                  alt="Koçlar için Randevu Paneli"
                  className="img-fluid mx-auto d-block"
                  style={{ borderRadius: 10, maxHeight: 350 }}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 40 }}>
            <Col lg="12">
              <div className="text-center mb-5">
                <h4>{t("Why Should You Use an Online Appointment System?")}</h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col md="12" sm="8">
              {/* featurebox */}
              <ul>
                <li className="font-size-16">
                  {t("Why Should You Use an Online Appointment System?_Text1")}
                </li>
                <li className="font-size-16">
                  {t("Why Should You Use an Online Appointment System?_Text2")}
                </li>
                <li className="font-size-16">
                  {t("Why Should You Use an Online Appointment System?_Text3")}
                </li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col lg="12" style={{ marginTop: 40 }}>
              <div className="text-center mb-5">
                <h4>{t("Images of the product")}</h4>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center" style={{ marginBottom: 300 }}>
            <Col md="8">
              <div>
                <img
                  src={feature4}
                  alt="Appointment Panel Dashboard"
                  className="img-fluid mx-auto d-block"
                  style={{ padding: 10, borderRadius: 10 }}
                />
              </div>
            </Col>
            <Col md="4">
              <div>
                <img
                  src={appointment_2}
                  alt="Appointment Panel Calendar"
                  className="img-fluid mx-auto d-block"
                  style={{ padding: 10, borderRadius: 10, maxHeight: 340 }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Features

import React, { useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

//Images
import client1 from "../../../../assets/images/clients/1.png"
import client2 from "../../../../assets/images/clients/2.png"
import client3 from "../../../../assets/images/clients/3.png"
import client4 from "../../../../assets/images/clients/4.png"
import client5 from "../../../../assets/images/clients/5.png"
import client6 from "../../../../assets/images/clients/6.png"

//swiper
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Autoplay } from "swiper/modules"
import "../../../../../node_modules/swiper/swiper.scss"
import { useTranslation } from "react-i18next"
import time from "../../../../assets/images/time_management.png"
import security from "../../../../assets/images/shield.png"

const AboutUs = () => {
  const { t, i18n } = useTranslation()

  return (
    <React.Fragment>
      <section className="section pt-4 bg-white" id="about">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="font-size-16 small-title">{t("About us")}</div>
                <h4 className="font-size-20">{t("Appointment Panel")}</h4>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="5">
              <div className="text-muted">
                <h4 className="font-size-20">
                  {t("What is Appointment Panel?")}
                </h4>
                <p className="font-size-16">{t("About us text")}</p>

                <div className="d-flex flex-wrap gap-2">
                  <a
                    href="mailto:info@ocalibus.com"
                    className="font-size-16 btn btn-success"
                  >
                    {t("Email")}
                  </a>
                  <a
                    href="tel:+905348384794"
                    className="font-size-16 btn btn-outline-primary"
                  >
                    {t("Phone")}
                  </a>
                </div>

                <Row className="mt-4">
                  <Col lg="4" xs="6">
                    <div className="mt-4">
                      <h4>1472</h4>
                      <p>{t("Appointments Count")}</p>
                    </div>
                  </Col>
                  <Col lg="4" xs="6">
                    <div className="mt-4">
                      <h4>761</h4>
                      <p>{t("Total Customer")}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg="6" className="ms-auto">
              <div className="mt-4 mt-lg-0">
                <Row>
                  <Col sm="6">
                    <Card className="border">
                      <CardBody>
                        <div className="mb-3">
                          <img
                            src={time}
                            alt="Appointment Panel Time Management"
                            height="29"
                          />
                        </div>
                        <h5 className="font-size-20">
                          {t("About us time management")}
                        </h5>
                        <p className="font-size-16 text-muted mb-0">
                          {t("About us 1")}
                        </p>
                      </CardBody>
                      {/*<div className="card-footer bg-transparent border-top text-center">
                        <Link to="#" className="text-primary">
                          Learn more
                        </Link>
                      </div>*/}
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card className="border mt-lg-5">
                      <CardBody>
                        <div className="mb-3">
                          <img
                            src={security}
                            alt="Appointment Panel Security"
                            height="29"
                          />
                        </div>
                        <h5 className="font-size-20">{t("About us secure")}</h5>
                        <p className="font-size-16 text-muted mb-0">
                          {t("About us 2")}
                        </p>
                      </CardBody>
                      {/*<div className="card-footer bg-transparent border-top text-center">
                        <Link to="#" className="text-primary">
                          Learn more
                        </Link>
                      </div>*/}
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {/*<hr className="my-5" />
          <Row>
            <Col lg={12}>
              <div className="hori-timeline">
                <Swiper
                  slidesPerView={1}
                  // spaceBetween={10}
                  navigation
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    678: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 4,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Pagination, Navigation, Autoplay]}
                  className="owl-carousel owl-theme clients-carousel"
                  id="clients-carousel"
                  dir="ltr"
                >
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client1}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client2}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client3}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client4}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client5}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client6}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>
          </Row>*/}
        </Container>
      </section>
    </React.Fragment>
  )
}

export default AboutUs

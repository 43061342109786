import React, { useState } from "react"
import { useTranslation } from "react-i18next"

const SpecializationDropdown = ({ onSelectSpecialization, onBlur, value }) => {
  const [selectedSpecialization, setSelectedSpecialization] = useState("")
  const { t, i18n } = useTranslation()

  const specializations = [
    t("Sleep Coach"),
    t("Breastfeeding Coach"),
    t("Birth Coach"),
    t("Pedagogue"),
    t("Psychology"),
    t("Dentistry"),
    t("Dermatology"),
    t("Cardiology"),
    t("Neurology"),
    t("Pediatrics"),
    t("Orthopedics"),
    t("Gynecology"),
    t("Oncology"),
    t("Hairdresser"),
  ]

  const handleSelect = event => {
    const value = event.target.value
    setSelectedSpecialization(value)
    if (onSelectSpecialization) {
      onSelectSpecialization(event)
    }
  }

  return (
    <div>
      <select
        id="specialization"
        name="specialization"
        value={selectedSpecialization}
        onChange={handleSelect}
        onBlur={onBlur}
        className="dropdown-select"
      >
        <option value="" disabled>
          {t("Select a specialization")}
        </option>
        {specializations.map(specialization => (
          <option key={specialization} value={specialization}>
            {specialization}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SpecializationDropdown

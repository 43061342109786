import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"
import { useTranslation } from "react-i18next"

const CardPricing = props => {
  const { t, i18n } = useTranslation()
  return (
    <React.Fragment>
      <Col xl="4" md="6">
        <Card className="plan-box">
          <CardBody className="p-4">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h5 className="font-size-18">{props.pricing.title}</h5>
                <p className="font-size-16 text-muted">
                  {props.pricing.description}
                </p>
              </div>
              <div className="flex-shrink-0 ms-3">
                <i
                  className={"bx " + props.pricing.icon + " h1 text-primary"}
                  style={{ lineHeight: 1 }}
                />
              </div>
            </div>
            <div className="py-4">
              <h2>
                <sup>
                  <small>{t("$")}</small>
                </sup>{" "}
                {props.pricing.price}/{" "}
                <span className="font-size-13">{props.pricing.duration}</span>
              </h2>
            </div>
            <div className="text-center plan-btn">
              <Link
                to={props.pricing.link}
                className="font-size-16 btn btn-primary btn-sm waves-effect waves-light "
              >
                {t("Sign up Now")}
              </Link>
            </div>

            <div className="plan-features mt-5">
              {props.pricing.features.map((feature, key) => (
                <p key={"_feature_" + key} className="font-size-16">
                  <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                  {feature.title}
                </p>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardPricing.propTypes = {
  pricing: PropTypes.object,
}

export default CardPricing

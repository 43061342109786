import {
  FETCH_EXPERTS_REQUEST,
  FETCH_EXPERTS_SUCCESS,
  FETCH_EXPERTS_FAILURE,
} from "./actionTypes"

export const fetchExpertsRequest = () => {
  return {
    type: FETCH_EXPERTS_REQUEST,
  }
}

export const fetchExpertsSuccess = experts => {
  return {
    type: FETCH_EXPERTS_SUCCESS,
    payload: experts,
  }
}

export const fetchExpertsFailure = error => {
  return {
    type: FETCH_EXPERTS_FAILURE,
    payload: error,
  }
}

import React from "react"
import { Link } from "react-router-dom"
import { Container, Card, CardBody, Col, Row } from "reactstrap"
import blogs from "./blogs.json"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// import images
import img1 from "../../assets/images/small/img-2.jpg"
import { useTranslation } from "react-i18next"
import Navbar_Page from "pages/HomePage/Navbar/Navbar"
import "pages/HomePage/styles.css"
import Footer from "pages/HomePage/Footer"

const BlogContent = () => {
  const { t, i18n } = useTranslation()
  let id = 0
  try {
    id = parseInt(window.location.href.split("/blog/")[1].split("/")[0], 10) - 1
  } catch (error) {}

  try {
    document.title = `${blogs.blogs[id].title} | ${t("Appointment Panel")}`
  } catch (error) {}
  return (
    <React.Fragment>
      <Navbar_Page />
      <div className="page-content">
        <Container style={{ marginBottom: 300 }}>
          <Breadcrumbs
            title="Blog"
            breadcrumbItem={
              blogs.blogs[id].title?.length > 30
                ? blogs.blogs[id].title.substring(0, 29) + "..."
                : blogs.blogs[id].title
            }
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <Row className="justify-content-center">
                      <Col xl={8}>
                        <div>
                          <div className="text-center">
                            <div className="mb-4">
                              <Link
                                to="#"
                                className="badge bg-light font-size-12"
                              >
                                <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                Randevu Paneli
                              </Link>
                            </div>
                            <h4>{blogs.blogs[id].title}</h4>
                            <p className="text-muted mb-4">
                              <i className="mdi mdi-calendar me-1"></i>{" "}
                              {blogs.blogs[id].date}
                            </p>
                          </div>

                          {/*<hr />
                          <div className="text-center">
                            <Row>
                              <Col sm={4}>
                                <div>
                                  <p className="text-muted mb-2">Categories</p>
                                  <h5 className="font-size-15">Project</h5>
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                  <p className="text-muted mb-2">Date</p>
                                  <h5 className="font-size-15">10 Apr, 2020</h5>
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                  <p className="text-muted mb-2">Post by</p>
                                  <h5 className="font-size-15">
                                    Gilbert Smith
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </div>*/}
                          <hr />

                          <div className="my-5">
                            <img
                              src={img1}
                              alt=""
                              className="img-thumbnail mx-auto d-block"
                            />
                          </div>

                          <hr />

                          <div className="mt-4">
                            <div className="text-muted font-size-14">
                              {blogs.blogs[id].content.map((item, index) => {
                                if (item.h5) {
                                  return (
                                    <h5 key={index} className="mt-3 mb-3">
                                      {item.h5}
                                    </h5>
                                  )
                                } else if (item.h6) {
                                  return (
                                    <h6 key={index} className="mt-3 mb-3">
                                      {item.h5}
                                    </h6>
                                  )
                                } else if (item.p) {
                                  return <p key={index}>{item.p}</p>
                                } else if (item.li) {
                                  return (
                                    <li key={index} style={{ lineHeight: 1.6 }}>
                                      {item.li}
                                    </li>
                                  )
                                }
                              })}
                            </div>

                            <hr />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default BlogContent

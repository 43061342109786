import { TopCitiesSelling } from "common/data"
import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"
import { useTranslation } from "react-i18next"

const TopCities = ({ users }) => {
  const { t, i18n } = useTranslation()
  const [cities, setCities] = useState([])
  const [totalCities, setTotalCities] = useState([])

  useEffect(() => {
    if (users) {
      let arr = []
      let total = 0
      users.map(item => {
        if (item.city) {
          total += 1
          if (
            arr.length === 0 ||
            arr.filter(af => af.city === item.city).length === 0
          ) {
            arr.push({ city: item.city, count: 1, color: "" })
          } else {
            const flagIndex = arr.findIndex(af => af.city === item.city)
            arr[flagIndex].count += 1
          }
        }
      })
      if (arr.length > 0) {
        arr = arr.sort((a, b) => b.count - a.count)
        if (arr.length >= 3) {
          arr = arr.slice(0, 3)
          arr[0].color = "primary"
          arr[1].color = "warning"
          arr[2].color = "success"
        }
      }
      setCities(arr)
      setTotalCities(total)
    }
  }, [users])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">
            {t("Cities with the Most Users")}
          </CardTitle>
          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
            <h3>{cities.length > 0 ? cities[0].count : "0"}</h3>
            <p>{cities.length > 0 ? cities[0].city : "-"}</p>
          </div>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                {(cities || [])?.map((item, index) => (
                  <tr key={index}>
                    <td style={{ width: "30%" }}>
                      <p className="mb-0">{item.city}</p>
                    </td>
                    <td style={{ width: "25%" }}>
                      <h5 className="mb-0">{item.count}</h5>
                    </td>
                    <td>
                      <Progress
                        value={(item.count * 100) / totalCities}
                        color={item.color}
                        className="bg-transparent progress-sm"
                        size="sm"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopCities

import React from "react"
import { Container, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"

const Footer = () => {
  const { t, i18n } = useTranslation()
  return (
    <React.Fragment>
      <footer className="footer-home">
        <Container>
          <Row>
            <Col md={4}>
              <h6 className="font-size-20">
                {new Date().getFullYear()} © Appointment Panel.
              </h6>
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <a style={{ cursor: "default" }}>Randevu Planlama</a>
                <a style={{ cursor: "default" }}>SMS Gönderimi</a>
                <a style={{ cursor: "default" }}>Randevu İstatistikleri</a>
              </div>
            </Col>
            <Col md={5}>
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <h6 className="font-size-20">Randevu Paneli Blog</h6>
                <a
                  href="/blog/1/randevu-paneli-kullanmanin-avantajlari"
                  style={{}}
                  className="font-size-14 text-muted"
                >
                  Randevu Paneli Kullanmanın Avantajları
                </a>
                <a
                  href="/blog/2/randevu-paneli-ile-musteri-deneyimi"
                  style={{}}
                  className="font-size-14 text-muted"
                >
                  Randevu Paneli ile Müşteri Deneyimi Nasıl İyileştirilir?
                </a>
                <a
                  href="/blog/3/randevu-panelleri-ile-zamandan-tasarruf"
                  style={{}}
                  className="font-size-14 text-muted"
                >
                  Randevu Panelleri ile Zamandan Tasarruf Etmenin 5 Yolu
                </a>
                <a
                  href="/blog/4/randevu-panellerinin-isletmeler-icin-onemi"
                  style={{}}
                  className="font-size-14 text-muted"
                >
                  Randevu Panellerinin İşletmeler İçin Önemi ve İhtiyaç Duyan
                  Sektörler
                </a>
                <a
                  href="/blog/5/dijital-donusumde-randevu-paneli"
                  style={{}}
                  className="font-size-14 text-muted"
                >
                  Dijital Dönüşümde Randevu Panellerinin Rolü: İşletmeler İçin
                  Neden Önemlidir?
                </a>
                <a
                  href="/blog/6/doktorlar-icin-randevu-paneli"
                  style={{}}
                  className="font-size-14 text-muted"
                >
                  Doktorlar İçin Randevu Panelleri: Hasta Memnuniyeti ve
                  Verimlilik İçin Neden Önemli?
                </a>
                <a
                  href="/blog/7/guzellik-merkezleri-icin-randevu-paneli"
                  style={{}}
                  className="font-size-14 text-muted"
                >
                  Güzellik Merkezleri İçin Randevu Paneli
                </a>
              </div>
            </Col>
            <Col md={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                <h6 className="font-size-20">
                  <a
                    href="https://www.ocalibus.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-muted"
                  >
                    Ocalibus Software
                  </a>
                </h6>
                <a
                  href="mailto:info@ocalibus.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  info@ocalibus.com
                </a>
                <a href="tel:+905348384794" target="_blank" rel="noreferrer">
                  0 534 838 47 94
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth"
import { initializeApp } from "firebase/app"
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore"
import moment from "moment"

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      app = initializeApp(firebaseConfig)
      firestore = getFirestore(app)
      auth = getAuth()
      onAuthStateChanged(auth, user => {
        try {
          if (user) {
            localStorage.setItem("authUser", JSON.stringify(user))
          } else {
            localStorage.removeItem("authUser")
          }
        } catch (error) {
          console.error("onAuthStateChanged", error)
        }
      })
    }
  }

  addNewEvent = async event => {
    try {
      if (!auth.currentUser) {
        throw new Error("User is not authenticated")
      }
      const details = {
        ...event,
        createdDtm: serverTimestamp(),
        lastLoginTime: serverTimestamp(),
      }
      const date = moment(event.start).format("DD-MM-YYYY").toString()

      const userDocRef = collection(
        firestore,
        `appointments/${auth.currentUser.uid}/${date}`
      )

      const docRef = await addDoc(userDocRef, details)
      try {
        const allUsers = await this.fetchUsers()
        const existUser = allUsers?.filter(
          item => item.id === event.clientPhoneNumber
        )
        if (existUser?.length > 0) {
          let user = { ...existUser[0] }
          user.appointmentsCount = user.appointmentsCount
            ? user.appointmentsCount + 1
            : 1
          this.updateUser(user)
        } else {
          const user = {
            name: event.clientName,
            email: event.clientEmail,
            phone: event.clientPhoneNumber,
            appointmentsCount: 1,
          }
          this.addNewUser(user)
        }
      } catch (err) {
        console.error(err)
      }

      return { details }
    } catch (error) {
      console.error(error)
      return null
    }
  }

  updateEvent = async event => {
    try {
      if (!auth.currentUser) {
        throw new Error("User is not authenticated")
      }
      const date = moment(event.start).format("DD-MM-YYYY").toString()
      const docRef = doc(
        collection(firestore, `appointments`),
        auth.currentUser.uid,
        date,
        event.id
      )

      await updateDoc(docRef, event)

      const appointmentsCollectionRef = doc(
        firestore,
        `appointments/${auth.currentUser.uid}/${date}/${event.id}`
      )

      const querySnapshot = await getDoc(appointmentsCollectionRef)
      let temp = { ...querySnapshot?.data() }
      const a = date.split("-")
      const newItem = a[2] + "-" + a[1] + "-" + a[0]
      temp.date = newItem
      return temp ?? null
    } catch (error) {
      console.error(error)
      return null
    }
  }

  fetchAppointmentsById = async (dates = []) => {
    if (!auth.currentUser) {
      throw new Error("User is not authenticated")
    }

    try {
      const uid = auth.currentUser.uid

      let appointments = []

      if (dates?.length > 0) {
        // `dates.map` içindeki her işlemi Promise.all ile bekleyeceğiz
        const promises = dates.map(item => this.fetchByMonth(uid, item))

        // Bütün Promiseleri bekleyelim
        const results = await Promise.all(promises)

        // Sonuçları düzleştirip (flatten) tek bir dizi haline getir
        results.forEach(arr => {
          if (arr?.length > 0) {
            appointments = appointments.concat(arr)
          }
        })
      }

      return appointments
    } catch (error) {
      console.log(error)
      return null
    }
  }

  fetchByMonth = async (uid, item) => {
    try {
      const appointmentsCollectionRef = collection(
        firestore,
        `appointments/${uid}/${item}`
      )

      const querySnapshot = await getDocs(appointmentsCollectionRef)
      const appointments = []
      querySnapshot.forEach(doc => {
        const a = item.split("-")
        const newItem = a[2] + "-" + a[1] + "-" + a[0]
        appointments.push({
          id: doc.id,
          date: newItem,
          ...doc.data(),
        })
      })
      return appointments
    } catch (error) {
      console.log("error", error)
      return []
    }
  }

  fetchAppointmentsById2 = async () => {
    if (!auth.currentUser) {
      throw new Error("User is not authenticated")
    }

    try {
      const uid = auth.currentUser.uid

      // Bu, "appointments/{uid}" altında bir koleksiyon referansı oluşturur
      const appointmentsCollectionRef = collection(
        firestore,
        `appointments/${uid}/17-08-2024`
      )

      const querySnapshot = await getDocs(appointmentsCollectionRef)

      const appointments = []
      querySnapshot.forEach(doc => {
        appointments.push({ id: doc.id, ...doc.data() })
      })

      return appointments
    } catch (error) {
      console.log(error)
      return null
    }
  }

  addNewExpertToFirestoreForMomercy = async () => {
    const details = {
      username: "Banu ÇİFTÇİ",
      email: "",
      phone: "05336872523",
      specialization: "Op. Dr.",
      details:
        "Lisans: Hacettepe Üniversitesi, Tıp Fakültesi, Ankara (1993-2000) Uzmanlık: Gazi Üniversitesi, Tıp Fakültesi, Kadın Hastalıkları ve Doğum AD, Ankara (2001-2005)Akademik: Bahçeşehir Üniversitesi Tıp Fakültesi, Kadın Hastalıkları ve Doğum Anabilim Dalı, Öğretim Üyesi Diplomalar: M.E.B, Dialog, Spikerlik Sunuculuk Diploması",
      user_profile: "",
      active: false,
      qa_active: false,
      video_active: false,
      city: "Şişli/İstanbul",
      address:
        "Terrace Fulya Center Teşvikiye Mahallesi Hakkı Yeten Caddesi No:11, Terrace Fulya Center 1, M2 Katı 34365 Fulya",
      createdDtm: serverTimestamp(),
    }

    const userDocRef = collection(
      firestore,
      `experts-momercy/${auth.currentUser.uid}/Kadın Doğum Doktoru`
    )

    const docRef = await addDoc(userDocRef, details)
    return { details }
  }

  addNewExpertToFirestore = async user => {
    const details = {
      username: user.username,
      email: user.email,
      specialization: user.specialization,
      createdDtm: serverTimestamp(),
      lastLoginTime: serverTimestamp(),
    }
    const userDocRef = doc(
      collection(firestore, "experts"),
      auth.currentUser.uid
    )
    await setDoc(userDocRef, details)

    return { details }
  }

  fetchExperts = async () => {
    try {
      const expertsCollection = collection(firestore, "experts")
      const snapshot = await getDocs(expertsCollection)
      const expertsList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }))
      return expertsList
    } catch (error) {
      console.error("Error fetching experts: ", error)
    }
  }

  fetchExpertById = async expertId => {
    const docRef = doc(firestore, "experts", expertId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data())
      return docSnap.data() // Veriyi return edebilir veya işleyebilirsiniz
    } else {
      console.log("No such document!")
      return null
    }
  }
  /**
   * Registers the user with given details
   */
  editProfileAPI = async (name, id) => {
    try {
      if (!auth.currentUser) {
        throw new Error("User is not authenticated")
      }

      const docRef = doc(collection(firestore, `experts`), auth.currentUser.uid)

      await updateDoc(docRef, { username: name })

      return name
    } catch (error) {
      console.error(error)
      return null
    }
  }

  registerUser = (email, password, username, specialization) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(auth, email, password).then(
        user => {
          this.addNewExpertToFirestore({ email, username, specialization })
          resolve(auth.currentUser)
        },
        error => {
          console.log("errorerror", error)
          reject(this._handleError(error))
        }
      )
    })
  }

  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then(userCredential => {
          // Oturum açmış kullanıcı bilgilerine erişim
          const user = userCredential.user
          resolve(user)
        })
        .catch(error => {
          // Hata yönetimi
          console.error("Error signing in:", error.message)
          reject(this._handleError(error.message))
        })
    })
  }

  checkUserForPremium = async () => {
    try {
      const experts = await this.fetchExperts()
      const user = experts?.filter(item => item.id === auth.currentUser.uid)
      const now = new Date()
      if (!user) return false
      if (user[0].premium && user[0].premiumDate.toDate() > now) {
        return true
      } else {
        this._handleError("İletişime geçiniz!")
        return false
      }
    } catch (error) {
      console.error(error)
      this._handleError("İletişime geçiniz!")
      return false
    }
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      auth
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      auth
        .signOut()
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Social Login user with given details
   */

  socialLoginUser = async type => {
    let provider
    if (type === "google") {
      provider = new firebase.auth.GoogleAuthProvider()
    } else if (type === "facebook") {
      provider = new firebase.auth.FacebookAuthProvider()
    }
    try {
      const result = await auth.signInWithPopup(provider)
      const user = result.user
      return user
    } catch (error) {
      throw this._handleError(error)
    }
  }

  addNewUser = async user => {
    try {
      if (!auth.currentUser) {
        throw new Error("User is not authenticated")
      }
      const details = {
        name: user.name,
        city: user.city,
        email: user.email,
        phone: user.phone,
        id: user.phone,
        appointmentsCount: user.appointmentsCount ?? 0,
        createdDtm: serverTimestamp(),
        lastLoginTime: serverTimestamp(),
      }

      const docRef = doc(
        collection(firestore, `clients`),
        auth.currentUser.uid,
        "users",
        user.phone
      )

      await setDoc(docRef, details)

      return details
    } catch (error) {
      console.error(error)
      return null
    }
  }

  updateUser = async user => {
    try {
      if (!auth.currentUser) {
        throw new Error("User is not authenticated")
      }

      const docRef = doc(
        collection(firestore, `clients`),
        auth.currentUser.uid,
        "users",
        user.id
      )

      await updateDoc(docRef, user)

      return user
    } catch (error) {
      console.error(error)
      return null
    }
  }

  deleteUser = async user => {
    try {
      if (!auth.currentUser) {
        throw new Error("User is not authenticated")
      }

      const docRef = doc(
        collection(firestore, `clients`),
        auth.currentUser.uid,
        "users",
        user
      )

      const sss = await deleteDoc(docRef)
      return user
    } catch (error) {
      return null
    }
  }

  getUser = async () => {
    try {
      if (!auth.currentUser) {
        throw new Error("User is not authenticated")
      }
      const experts = await this.fetchExperts()
      const user = experts?.filter(item => item.id === auth.currentUser.uid)
      return user?.length > 0 ? user[0] : null
    } catch (error) {
      console.error(error)
      return null
    }
  }

  fetchUsers = async () => {
    try {
      if (!auth.currentUser) {
        throw new Error("User is not authenticated")
      }
      const docRef = collection(
        firestore,
        "clients",
        auth.currentUser.uid,
        "users"
      )
      const docSnap = await getDocs(docRef)
      const expertsList = docSnap.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }))
      return expertsList
    } catch (error) {
      console.error(error)
      return null
    }
  }

  addNewUserToFirestore = user => {
    const collection = firebase.firestore().collection("users")
    const { profile } = user.additionalUserInfo
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
    }
    collection.doc(auth.currentUser.uid).set(details)
    return { user, details }
  }

  setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user))
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null
    return JSON.parse(localStorage.getItem("authUser"))
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseBackend = null
let app = null
let firestore = null
let auth = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }

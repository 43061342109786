import React from "react"
import { Container, Row, Col } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Pricing Cards
import CardPricing from "./card-pricing"
import { useTranslation } from "react-i18next"

const PagesPricing = ({}) => {
  const { t, i18n } = useTranslation()
  //meta title

  const pricings = [
    /*{
      id: 1,
      title: t("Starter"),
      description: t("Basic plan"),
      icon: "bx-walk",
      price: t("Basic plan price"),
      duration: t("Per month"),
      link: "register",
      features: [
        { title: t("Unlimited Data Storage") },
        { title: `${t("Unlimited Client")}` },
        { title: `${t("Client Management")}` },
        { title: `${t("Language Option")}` },
        { title: `${t("Appointment Reminder")}` },
        { title: `50 ${t("SMS")}` },
        { title: `1 ${t("Specialization")}` },
      ],
    },
    {
      id: 2,
      title: t("Professional"),
      description: t("Standard plan"),
      icon: "bx-run",
      price: t("Standard plan price"),
      duration: t("Per month"),
      link: "register",
      features: [
        { title: t("Unlimited Data Storage") },
        { title: `${t("Unlimited Client")}` },
        { title: `${t("Client Management")}` },
        { title: `${t("Language Option")}` },
        { title: `${t("Appointment Reminder")}` },
        { title: `200 ${t("SMS")}` },
        { title: `1 ${t("Specialization")}` },
      ],
    },*/
    {
      id: 3,
      title: t("Enterprise"),
      description: t("Premium plan"),
      icon: "bx-cycling",
      price: t("Premium plan price"),
      duration: t("Per month"),
      link: "register",
      features: [
        { title: t("Unlimited Data Storage") },
        { title: t("Unlimited Client") },
        { title: `${t("Client Management")}` },
        { title: `${t("Language Option")}` },
        { title: `${t("Appointment Reminder")}` },
        { title: `${t("Unlimited")} SMS` },
        { title: `1 ${t("Specialization")}` },
      ],
    },
    {
      id: 4,
      title: t("Unlimited"),
      description: t("High plan"),
      icon: "bx-car",
      price: t("High plan price"),
      duration: t("Per month"),
      link: "register",
      features: [
        { title: t("Unlimited Data Storage") },
        { title: t("Unlimited Client") },
        { title: `${t("Client Management")}` },
        { title: `${t("Language Option")}` },
        { title: `${t("Appointment Reminder")}` },
        { title: `${t("Unlimited")} SMS` },
        { title: t("Unlimited Specialization") },
        { title: t("New Website Where Clients Can Make Appointments") },
      ],
    },
  ]
  return (
    <React.Fragment>
      <section
        className="section hero-section bg-ico-hero"
        id="prices"
        style={{ paddingTop: 40, paddingBottom: 40 }}
      >
        <Container>
          {/* Render Breadcrumbs 
          <Breadcrumbs title="Utility" breadcrumbItem="Pricing" />*/}

          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center mb-5">
                <h3>{t("Choose your Pricing plan")}</h3>
                <p className="font-size-16 text-muted">
                  {t("Choose your Pricing plan subtext")}
                </p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            {pricings.map((pricing, key) => (
              <CardPricing pricing={pricing} key={"_pricing_" + key} />
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}
export default PagesPricing

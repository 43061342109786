import React, { useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

//swiper
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Autoplay } from "swiper/modules"
import "../../../node_modules/swiper/swiper.scss"
import { useTranslation } from "react-i18next"
import time from "../../assets/images/guzellik-merkezi-randevu-paneli.jpg"
import security from "../../assets/images/kuafor-randevu-paneli.jpg"
import doctor from "../../assets/images/doktor-klinik-muayene-randeve-paneli.jpg"
import diyetisyen from "../../assets/images/diyetisyen-randevu-paneli.jpg"
import piercing from "../../assets/images/piercing-tattoo-randevu-paneli.jpg"
import gym from "../../assets/images/spor-salonu-randevu-paneli.jpg"
import psikolog from "../../assets/images/psikolog-randevu-paneli.jpg"
import spa from "../../assets/images/spa-randevu-paneli.jpg"

const Companies = () => {
  const { t, i18n } = useTranslation()

  return (
    <React.Fragment>
      <section className="section pt-4 bg-white" id="about">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title font-size-16">
                  {t("For Everyone")}
                </div>
                <h4 className="font-size-20">{t("Appointment Panel")}</h4>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="6" className="ms-auto">
              <div className="mt-4 mt-lg-0">
                <Row>
                  <Col sm="6">
                    <Card className="border">
                      <CardBody style={{ padding: 0 }}>
                        <div
                          className="mb-3 justify-content-center"
                          style={{ display: "flex" }}
                        >
                          <img
                            src={time}
                            alt="Beauty Salon Appointment"
                            width="100%"
                          />
                        </div>
                        <h5
                          className="font-size-18"
                          style={{ paddingLeft: 16 }}
                        >
                          {t("Beauty Salon")}
                        </h5>
                        <p
                          style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingBottom: 16,
                          }}
                          className="font-size-16 text-muted mb-0"
                        >
                          {t("Beauty Salon 1")}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card className="border mt-lg-5">
                      <CardBody style={{ padding: 0 }}>
                        <div
                          className="mb-3 justify-content-center"
                          style={{ display: "flex" }}
                        >
                          <img
                            src={security}
                            alt="Hairdresser Appointment Panel"
                            width="100%"
                          />
                        </div>
                        <h5
                          className="font-size-18"
                          style={{ paddingLeft: 16 }}
                        >
                          {t("Hairdresser")}
                        </h5>
                        <p
                          style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingBottom: 16,
                          }}
                          className="font-size-16 text-muted mb-0"
                        >
                          {t("Hairdresser 1")}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg="6" className="ms-auto">
              <div className="mt-4 mt-lg-0">
                <Row>
                  <Col sm="6">
                    <Card className="border">
                      <CardBody style={{ padding: 0 }}>
                        <div
                          className="mb-3 justify-content-center"
                          style={{ display: "flex" }}
                        >
                          <img
                            src={doctor}
                            alt="Clinical Appointment Panel"
                            width="100%"
                          />
                        </div>
                        <h5
                          className="font-size-18"
                          style={{ paddingLeft: 16 }}
                        >
                          {t("Clinical")}
                        </h5>
                        <p
                          style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingBottom: 16,
                          }}
                          className="font-size-16 text-muted mb-0"
                        >
                          {t("Clinical 1")}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card className="border mt-lg-5">
                      <CardBody style={{ padding: 0 }}>
                        <div
                          className="mb-3 justify-content-center"
                          style={{ display: "flex" }}
                        >
                          <img
                            src={diyetisyen}
                            alt="Dietician Appointment Panel"
                            width="100%"
                          />
                        </div>
                        <h5
                          className="font-size-18"
                          style={{ paddingLeft: 16 }}
                        >
                          {t("Dietician")}
                        </h5>
                        <p
                          style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingBottom: 16,
                          }}
                          className="font-size-16 text-muted mb-0"
                        >
                          {t("Dietician 1")}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg="6" className="ms-auto">
              <div className="mt-4 mt-lg-0">
                <Row>
                  <Col sm="6">
                    <Card className="border">
                      <CardBody style={{ padding: 0 }}>
                        <div
                          className="mb-3 justify-content-center"
                          style={{ display: "flex" }}
                        >
                          <img
                            src={piercing}
                            alt="Piercing & Tattoo Appointment Panel"
                            width="100%"
                          />
                        </div>
                        <h5
                          className="font-size-18"
                          style={{ paddingLeft: 16 }}
                        >
                          {t("Piercing & Tattoo")}
                        </h5>
                        <p
                          style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingBottom: 16,
                          }}
                          className="font-size-16 text-muted mb-0"
                        >
                          {t("Piercing & Tattoo 1")}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card className="border mt-lg-5">
                      <CardBody style={{ padding: 0 }}>
                        <div
                          className="mb-3 justify-content-center"
                          style={{ display: "flex" }}
                        >
                          <img
                            src={gym}
                            alt="Gym Appointment Panel"
                            width="100%"
                          />
                        </div>
                        <h5
                          className="font-size-18"
                          style={{ paddingLeft: 16 }}
                        >
                          {t("Gym")}
                        </h5>
                        <p
                          style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingBottom: 16,
                          }}
                          className="font-size-16 text-muted mb-0"
                        >
                          {t("Gym 1")}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg="6" className="ms-auto">
              <div className="mt-4 mt-lg-0">
                <Row>
                  <Col sm="6">
                    <Card className="border">
                      <CardBody style={{ padding: 0 }}>
                        <div
                          className="mb-3 justify-content-center"
                          style={{ display: "flex" }}
                        >
                          <img
                            src={spa}
                            alt="Spa Appointment Panel"
                            width="100%"
                          />
                        </div>
                        <h5
                          className="font-size-18"
                          style={{ paddingLeft: 16 }}
                        >
                          {t("Spa")}
                        </h5>
                        <p
                          style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingBottom: 16,
                          }}
                          className="font-size-16 text-muted mb-0"
                        >
                          {t("Spa 1")}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card className="border mt-lg-5">
                      <CardBody style={{ padding: 0 }}>
                        <div
                          className="mb-3 justify-content-center"
                          style={{ display: "flex" }}
                        >
                          <img
                            src={psikolog}
                            alt="Psychologists and Counselors Appointment Panel"
                            width="100%"
                          />
                        </div>
                        <h5
                          className="font-size-18"
                          style={{ paddingLeft: 16 }}
                        >
                          {t("Psychologists and Counselors")}
                        </h5>
                        <p
                          style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingBottom: 16,
                          }}
                          className="font-size-16 text-muted mb-0"
                        >
                          {t("Psychologists and Counselors 1")}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {/*<hr className="my-5" />
          <Row>
            <Col lg={12}>
              <div className="hori-timeline">
                <Swiper
                  slidesPerView={1}
                  // spaceBetween={10}
                  navigation
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    678: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 4,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Pagination, Navigation, Autoplay]}
                  className="owl-carousel owl-theme clients-carousel"
                  id="clients-carousel"
                  dir="ltr"
                >
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client1}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client2}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client3}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client4}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client5}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client6}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>
          </Row>*/}
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Companies

import React from "react"
import Header from "./Header"
import Hero from "./Hero"
import "./styles.css"
import PagesPricing from "pages/Utility/pages-pricing"
import ProductInfo from "./ProductInfo"
import Footer from "./Footer"
import FAQs from "pages/Crypto/CryptoIcoLanding/Faqs/FAQs"
import Blog from "pages/Crypto/CryptoIcoLanding/Blog/blog"
import OurTeam from "pages/Crypto/CryptoIcoLanding/Team/our-team"
import RoadMap from "pages/Crypto/CryptoIcoLanding/RoadMap/road-map"
import Features from "pages/Crypto/CryptoIcoLanding/Features/features"
import AboutUs from "pages/Crypto/CryptoIcoLanding/AboutUs/about-us"
import CardsMini from "pages/Crypto/CryptoIcoLanding/HeroSection/cards-mini"
import Section from "./Section"
import Navbar_Page from "./Navbar/Navbar"
import { useTranslation } from "react-i18next"
import Companies from "./Companies"
const HomePage = () => {
  const { t, i18n } = useTranslation()

  document.title = t("Home | Appointment Panel - Ocalibus Software")
  return (
    <React.Fragment>
      {/* Hero section */}
      <Navbar_Page />
      <Section />
      <Companies />
      <PagesPricing />

      {/* mini cards 
      <CardsMini />*/}

      {/* aboutus */}
      <AboutUs />

      {/* features */}
      <Features />

      {/* roadmap 
      <RoadMap />*/}

      {/* faqs 
      <FAQs />*/}

      {/* footer */}
      <Footer />
    </React.Fragment>
  )
}

export default HomePage
